import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        lng: localStorage.getItem('userLanguage') || 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    common: {
                        email: 'Email',
                        password: 'Password',
                        confirm_password: 'Confirm password',
                        first_name: 'First name',
                        last_name: 'Last name',
                        telephone: 'Phone',
                        optional: 'Optional',
                        send: 'Send',
                        submit: 'Submit',
                        cancel: 'Cancel',
                        confirm: 'Confirm',
                        back: 'Back',
                        details: 'Details',
                        question: 'Question',
                        site_busy_error: 'The site is busy, please try again later',
                    },
                    checkout: {
                        days_full_access: 'days full access',
                        discount: 'discount',
                        subscribe: 'Subscribe',
                        subscription_note: 'For more information, please contact us.',
                        contact_us: 'Contact Us',
                    },
                    header: {
                        homepage: 'Home',
                        objective_testing: 'Objective testing',
                        listening_comprehension: 'Listening Comprehension',
                        reading_comprehension: 'Reading Comprehension',
                        subjective_testing: 'Subjective testing',
                        speaking_comprehension: 'Expression Orale',
                        writing_comprehension: 'Expression Écrite',
                        Subscription: 'Subscription',
                        my_profile: "My Profile",
                    },
                    login: {
                        sign_up: 'Sign up',
                        login: 'Login',
                        login_field_missing: 'Email or password is missing',
                        login_failed_mismatch: 'Login failed, please check your username and password',
                        login_failed_server: 'Login failed, please try again later',
                        logout: 'Logout',
                    },
                    register: {
                        email_verification_code: "Email verification code",
                        send_verification_code: "Send verification code",
                        enter_CAPTCHA: "Enter CAPTCHA",
                        register_field_missing: 'Email, password, first name, last name, and CAPTCHA code are required',
                        register_succeed: "You have registered successfully!",
                        register_failed_later: "Failed to register, please try again later",
                        back_to_login: "Back to previous page",
                    },
                    profile: {
                        no_plan: "Don't have a plan",
                        membership_intro: "Your full access will be available until",
                        extend_membership: "Extend membership",
                        update_password: "Update password",
                        account: "Account",
                        check_subscription: "Check subscription plans",
                        non_membership_header: "Become a full member to access to the following resources",
                        membership_header: "You are a full member, with access to the following resources",
                        membership_right_1: "25 real TCF Canada listening tests + unlimited AI tutoring",
                        membership_right_2: "29 real TCF Canada reading tests + unlimited AI tutoring",
                        membership_right_3: "Up-to-date speaking topics from real TCF exams + AI assessment and tutoring",
                        membership_right_4: "Up-to-date writing topics from real TCF exams + AI assessment and tutoring",
                        change_password_field_missing: "Required fields are missing",
                        password_not_match: "Password doesn't match",
                        change_password_failed: "Failed to change password",
                    },
                    comprehension: {
                        become_member_intro: "You need to become a full member to access all real tests.",
                        become_member_button: "Become a member",
                        listening_test_label_prefix: "CO - Test ",
                        reading_test_label_prefix: "CE - Test ",
                        test: "Test",
                        train: "Train",
                    },
                    test_question: {
                        previous_question: "Previous question",
                        next_question: "Next question",
                        transcript: "Transcript",
                        breakdown: "Breakdown",
                        show_transcript: "Show Transcript",
                        show_breakdown: "Show Breakdown",
                        reference_answer: "Reference Answer",
                        show_analysis: "Show Analysis",
                        finish: "Finish",
                        confirm_finish_test: "Confirm to finish the test?",
                    },
                    test_result: {
                        test_result: "Test result",
                        correct: "Correct",
                        wrong: "Wrong",
                        answered: "Answered",
                        not_answered: "Not answered",
                        total_score: "Total score",
                        score: "Score",
                        correct_answer: "Correct answer",
                        your_answer: "Your answer",
                        under_levels: "Under 4"
                    }
                }
            },
            fr: {
                translation: {
                    common: {
                        email: 'Courriel',
                        password: 'Mot de Passe',
                        confirm_password: 'Confirmez mot de passe',
                        first_name: 'Prénom',
                        last_name: 'Nom',
                        telephone: 'Téléphone',
                        optional: 'facultatif',
                        send: 'Envoyer',
                        submit: 'S’inscrire',
                        cancel: 'Annuler',
                        confirm: 'Confirmer',
                        back: 'Retour',
                        details: 'Détails',
                        question: 'Question',
                        site_busy_error: 'Le site est occupé, veuillez réessayer plus tard',
                    },
                    header: {
                        homepage: 'Accueil',
                        objective_testing: 'Épreuves d\'Objectives',
                        listening_comprehension: 'Compréhension Orale',
                        reading_comprehension: 'Compréhension Écrite',
                        subjective_testing: 'Épreuves d\'Expressions',
                        speaking_comprehension: 'Expression Orale',
                        writing_comprehension: 'Expression Écrite',
                        Subscription: 'Abonnement',
                        my_profile: "Mon profil",
                    },
                    checkout: {
                        days_full_access: 'jours d\'accès complet',
                        discount: 'réduction',
                        subscribe: 'S\'abonner',
                        subscription_note: 'Pour plus d\'informations, veuillez nous contacter.',
                        contact_us: 'Contactez-nous',
                    },
                    login: {
                        sign_up: 'S\'inscrire',
                        login: 'Se connecter',
                        login_field_missing: 'L\'email ou le mot de passe est manquant',
                        login_failed_mismatch: 'Échec de la connexion, veuillez vérifier votre nom d\'utilisateur et votre mot de passe',
                        login_failed_server: 'Échec de la connexion, veuillez réessayer plus tard',
                        logout: 'Se déconnecter',
                    },
                    register: {
                        email_verification_code: "Code de vérification du courriel",
                        send_verification_code: "Envoyer le code de vérification",
                        enter_CAPTCHA: "Entrez le CAPTCHA",
                        register_field_missing: 'L\'adresse électronique, le mot de passe, le prénom, le nom de famille et le code CAPTCHA sont obligatoires.',
                        register_succeed: "Vous vous êtes bien inscrit(e) !",
                        register_failed_later: "Échec de l'inscription, veuillez réessayer plus tard",
                        back_to_login: "Retourner à la page précédente",
                    },
                    profile: {
                        no_plan: "Ne pas avoir de plan",
                        membership_intro: "Votre accès complet sera disponible jusqu'à",
                        extend_membership: "Prolonger l'adhésion",
                        update_password: "Mettre à jour le mot de passe",
                        account: "Compte",
                        check_subscription: "Vérifier les plans d'abonnement",
                        non_membership_header: "Devenez un membre à part entière pour accéder aux ressources suivantes",
                        membership_header: "Vous êtes membre à part entière et avez accès aux ressources suivantes",
                        membership_right_1: "25 tests d'écoute réels du TCF Canada + tutorat AI illimité",
                        membership_right_2: "29 vrais tests de lecture du TCF Canada + tutorat AI illimité",
                        membership_right_3: "Sujets d'expression orale actualisés tirés d'examens réels du TCF + évaluation et tutorat AI",
                        membership_right_4: "Sujets d'écriture actualisés tirés d'examens TCF réels + évaluation et tutorat AI",
                        change_password_field_missing: "Les champs obligatoires sont manquants",
                        password_not_match: "Le mot de passe ne correspond pas",
                        change_password_failed: "Échec du changement de mot de passe",
                    },
                    comprehension: {
                        become_member_intro: "Vous devez devenir membre à part entière pour accéder à tous les vrais tests.",
                        become_member_button: "Devenir membre",
                        listening_test_label_prefix: "CO - Test ",
                        reading_test_label_prefix: "CE - Test ",
                        test: "Test",
                        train: "Train",
                    },
                    test_question: {
                        previous_question: "Question précédente",
                        next_question: "Question suivante",
                        transcript: "Transcription",
                        breakdown: "Détails",
                        show_transcript: "Afficher la transcription",
                        show_breakdown: "Afficher les détails",
                        reference_answer: "Réponse de référence",
                        show_analysis: "Afficher l'analyse",
                        finish: "Terminer",
                        confirm_finish_test: "Confirmer pour terminer le test?",
                    },
                    test_result: {
                        test_result: "Résultat du test",
                        correct: "Correct",
                        wrong: "erreur",
                        answered: "Répondu",
                        not_answered: "Non répondu",
                        total_score: "Score total",
                        score: "Score",
                        correct_answer: "Réponse correcte",
                        your_answer: "Votre réponse",
                        under_levels: "Sous 4"
                    }
                }
            }
        }
    });

export default i18n;
