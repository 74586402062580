import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {useEffect} from "react";

const IsLogin = ({children}: any) => {

    const [cookies] = useCookies(['Authorization']);

    const navigate = useNavigate()

    useEffect(() => {
        if(!cookies.Authorization||cookies.Authorization==='undefined'){
            navigate("/Login")
            window.location.reload(); // Force page reload
        }
    }, [cookies.Authorization])

    return children
};

export default IsLogin
