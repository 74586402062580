import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    styles: {
        global: {
            body: {
                margin: 0,
                padding: 0,
                boxSizing: 'border-box',
            },
        },
    },
    colors: {
        blackAlpha: {
            500: "#000000", // 设置主要的按钮颜色为纯黑
            600: "#000000", // 设置悬停时的颜色也为纯黑
        },
    },
    components: {
        // for header menu style
        Button: {
            variants: {
                link: {
                    color: 'white',
                    // fontWeight: 'bold',
                    // fontWeight: 'normal',
                },
            },
        },
        // for register and login page input field style
        Input: {
            variants: {
                outline: {
                    field: {
                        border: "2px solid",
                        borderColor: "gray.300",
                        _focus: {
                            borderColor: "blue.500",
                            boxShadow: "0 0 0 1px blue.500",
                        }
                    }
                }
            },
            defaultProps: {
                variant: 'outline',
            }
        }
    }
});

export default theme;